.adminPage {
	width: 100%;
	font-family: 'RobotoUpperCase';
	font-size: 16px;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center
}

.adminPage .header {
	text-align: center;
	font-size: 24px
}

.adminPage .searchAndAdd {
	margin-top: 20px;
	display: flex
}

@media only screen and (max-width:768px) {
	.adminPage .searchAndAdd {
		flex-direction: column;
		width: 100%;
		align-items: center
	}
}

.adminPage .searchAndAdd>div {
	display: flex;
	flex-direction: column;
	margin-right: 30px
}

@media only screen and (max-width:768px) {
	.adminPage .searchAndAdd>div {
		margin-right: 0
	}
}

.adminPage .searchAndAdd .phoneNumber .inputNumber {
	margin-top: -20px;
	margin-left: -40px
}

@media only screen and (max-width:768px) {
	.adminPage .searchAndAdd .phoneNumber .inputNumber {
		margin-left: -20px;
		margin-top: 0
	}
}

.adminPage .searchAndAdd .searchResult .result {
	border-radius: 30px;
	padding: 12px 15px;
	width: 250px;
	min-height: 57px;
	margin-left: -15px;
	margin-top: 22px;
	background: linear-gradient(to right, #151722, #11121b);
	border: 1px solid #23263e
}

@media only screen and (max-width:768px) {
	.adminPage .searchAndAdd .searchResult .result {
		margin-top: 10px;
		width: 420px;
		max-width: 100%
	}
}

.adminPage .selectPermissionsHeader {
	text-align: center;
	font-size: 24px;
	margin-top: 20px
}

.adminPage .addAdminpermissionsWrapper {
	display: flex;
	flex-direction: column;
	align-items: center
}

.adminPage .addAdminpermissionsWrapper .permissions {
	font-family: 'RobotoUpperCase';
	font-size: 16px;
	color: white;
	display: flex;
	align-items: center
}

.adminPage .addAdminpermissionsWrapper .permissions .checkboxText {
	font-size: 20px
}

.adminPage .addAdminpermissionsWrapper .addAdminUniListWrapper {
	margin-top: 15px
}

.adminPage .addAdminpermissionsWrapper .addAdminUniListWrapper .addAdminUniList h2 {
	text-align: center
}

.adminPage .addAdminpermissionsWrapper .addAdminProgramListWrapper {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	align-items: center
}

.adminPage .addAdminpermissionsWrapper .addAdminProgramListWrapper .addAdminProgramList {
	margin-top: 0
}

.adminPage .addAdminpermissionsWrapper .addAdminProgramListWrapper .addAdminProgramList h2 {
	text-align: center
}

.adminPage .ableToAddAdminPermissionWrapper {
	font-size: 24px;
	margin-top: 20px
}