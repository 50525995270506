.cu-popup {
	width: calc(100vw - 15px);
	max-width: 900px
}

.cu-popup .scholarship-part {
	width: 100%;
	background: linear-gradient(to right, #21a0ee, #0a468c);
	border-radius: 10px 10px 0 0;
	padding: 88px 60px 15px
}

.cu-popup .cu-scholarship-part {
	width: 100%;
	padding: 15px 15px 30px 55px;
	background: white;
	border-radius: 0 0 10px 10px
}

.cu-popup .cu-scholarship-part .info-part {
	display: inline-block;
	width: 45%;
	text-align: left;
	vertical-align: middle;
	margin-right: 30px
}

.cu-popup .cu-scholarship-part .info-part div.cu-see-all-button {
	text-align: center
}

.cu-popup .cu-scholarship-part .info-part div.cu-see-all-button>button {
	padding: 9px 25px 5px 25px;
	color: white;
	border: none;
	border-radius: 200px;
	background: linear-gradient(to right, #0a468c, #21a0ee);
	font-family: "RobotoUpperCase";
	font-size: 19px;
	cursor: pointer;
	outline: none
}

.cu-popup .cu-scholarship-part .logo-part {
	display: inline-block;
	width: calc(55% - 30px);
	vertical-align: middle
}

.cu-popup .all-offers-popup-container {
	background: transparent;
	border-radius: 10px;
	width: calc(100vw - 20px);
	max-width: 800px
}

.cu-popup .all-offers-popup-container .offer {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 10px
}

@media (max-width:500px) {
	.cu-popup .all-offers-popup-container .offer {
		margin-top: 25px
	}
}

.cu-popup .all-offers-popup-container .offer .header {
	background: linear-gradient(to right, #0a468c, #21a0ee);
	color: white;
	padding: 15px 20px;
	border-radius: 10px 10px 0 0
}

.cu-popup .all-offers-popup-container .offer .content {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	padding: 15px 20px;
	background: white;
	color: black;
	border-radius: 0 0 10px 10px;
	height: 100%;
	text-align: left
}

.cu-popup .all-offers-popup-container .offer .content button {
	padding: 9px 25px 5px 25px;
	border: none;
	border: 1px solid #21a0ee;
	color: #21a0ee;
	border-radius: 200px;
	background: white;
	font-family: "RobotoUpperCase";
	font-size: 19px;
	cursor: pointer;
	outline: none
}

.cu-popup .all-offers-popup-container .top-offers {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	margin-bottom: 30px
}

@media (max-width:500px) {
	.cu-popup .all-offers-popup-container .top-offers {
		display: block
	}
}

.cu-popup .all-offers-popup-container .bottom-offers .offer {
	width: 50%;
	margin: 0 auto
}

@media (max-width:500px) {
	.cu-popup .all-offers-popup-container .bottom-offers .offer {
		margin-top: 0;
		width: 100%
	}
}