.chooseSubjectsContainer {
	background: white;
	border-radius: 15px;
	padding: 20px 40px;
	margin: 40px auto;
	width: 700px;
	max-width: 100%;
	font-family: 'RobotoUpperCase'
}

.chooseSubjectsContainer h2 {
	text-align: center
}