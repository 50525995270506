.fancyMultipleSelectCont {
	display: inline-block;
	width: 400px;
	max-width: 100%
}

.fancyMultipleSelectCont .fancyMultipleSelect {
	width: 100%;
	height: 55px;
	background: linear-gradient(to right, #151722, #11121b);
	padding: 0 17px;
	border: 1px solid #23263e;
	border-radius: 50px;
	color: #d5d5d6;
	font-size: 18px;
	font-family: 'RobotoUpperCase'
}

.fancyMultipleSelectCont .fancyMultipleSelect .icon {
	color: white
}